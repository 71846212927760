@import "global/01-mixins";
@import "fonts";
@import "global/03-vars";
html {
    box-sizing: border-box;
}

body {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 1;
    font-weight: 400;
    color: #000;
    position: relative;
    height: 100%;
    background: #fff;
    -webkit-tap-highlight-color: transparent;
}

div,
section,
footer,
.btn {
    box-sizing: border-box;
}

a {
    color: #000;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -ms-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    transition: all 0.1s linear;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:focus,
input:focus {
    outline: none;
}

p {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #121212;
    margin: 0 0 10px 0;
}

input {
    display: block;
    width: 100%;
    background: #F4F4F4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
    height: 59px;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    padding: 0 24px;
    color: #121212;
    color: #8C8C8C;
    border-radius: 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -ms-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    transition: all 0.1s linear;
    border: none;
}

input:hover,
input:focus {
    outline: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul:not(.menu, .list) li {
    position: relative;
    padding: 0 0 0 17px;
    margin-bottom: 8px;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #121212;
}

ul:not(.menu, .list) li::before {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    position: absolute;
    top: 8px;
    left: 0;
    background: #EC441E;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

button {
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -ms-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    transition: all 0.1s linear;
}

.title {
    font-weight: 600;
    font-size: 40px;
    line-height: 112%;
    letter-spacing: -0.02em;
    color: #121212;
    margin-bottom: 20px;
}

.desc {
    color: #000;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    margin-bottom: 15px;
}

img {
    max-width: 100%;
    max-height: 100%;
    display: block;
}

.clearfix:after {
    content: '';
    display: table;
    clear: both;
}

.wrapper {
    position: relative;
}

.ov-hidden {
    overflow: hidden;
}

.fade {
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear
}

.fade:not(.show) {
    opacity: 0
}

@media (min-width:576px) {
    .modal-dialog {
        margin: 0 auto;
        max-width: 560px
    }
}

.modal-dialog {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    max-width: 560px;
    position: relative;
    width: auto;
    height: 100vh;
    pointer-events: none
}

.modal-content {
    padding: 71px 57px 71px 57px;
    background: #fff;
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    max-width: 560px;
    width: 100%;
    pointer-events: auto;
    border: none;
    outline: 0
}

.close:not(:disabled):not(.disabled) {
    cursor: pointer
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background: rgba(10, 22, 30, 0.9);
    z-index: 9999;
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
    -webkit-transition: transform .3s ease-out;
    -moz-transition: transform .3s ease-out;
    -ms-transition: transform .3s ease-out;
    -o-transition: transform .3s ease-out
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
    -webkit-transition: transform .3s ease-out;
    -moz-transition: transform .3s ease-out;
    -ms-transition: transform .3s ease-out;
    -o-transition: transform .3s ease-out
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
}

.modal-close {
    position: absolute;
    width: 18px;
    height: 18px;
    background: url(../img/close.svg) no-repeat center;
    right: 31px;
    top: 31px;
    font-size: 25px;
    font-weight: 400;
    text-shadow: none;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
    opacity: 1
}

.form-popup {
    padding: 0;
}

.modal-open {
    overflow: hidden;
    padding-right: 17px!important;
}

.btn-orange {
    font-weight: 600;
    font-size: 24px;
    padding: 0 0 0 0;
    line-height: 29px;
    text-align: center;
    border: 2px solid #E51C15;
    color: #EC441E;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.header {
    padding: 0 0 0 0;
    margin-bottom: 93px;
    @include tr2;
    .container {
        max-width: 1170px;
    }
}

.header.fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    background: #fff;
    padding: 20px 0;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
}

.header.fixed+.top-wrap {
    padding: 0 0 300px 0;
}

.wrapper {
    position: relative;
}

.top-img {
    position: absolute;
    height: 0;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 890px;
    // padding-bottom: 46.8%;
    z-index: -1;
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(68.66deg, rgba(100, 100, 100, 0.81) 0%, rgba(255, 255, 255, 0) 100%);
    }
    img {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.header-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .logo {
        margin-right: 126px;
        position: relative;
        padding: 0 0 0 38px;
        span {
            white-space: nowrap;
            font-family: "Aldrich";
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: #121212;
        }
        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: -10px;
            width: 30px;
            height: 40px;
            background: url(../img/logo.svg) no-repeat left center;
        }
    }
    .btn-orange {
        display: block;
        max-width: 173px;
        width: 100%;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        padding: 10px 0 10px;
        @include tr2;
    }
}

.menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    li {
        margin-right: 64px;
        &:last-child {
            margin-right: 0;
        }
    }
    a {
        display: block;
        padding: 11px 0 11px 0;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #121212;
        &:hover {
            color: #EC441E;
        }
    }
}

.phone {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #121212;
    margin-left: 115px;
}

.btn-orange {
    margin-left: auto;
}

input:not([type=checkbox]):not([type=radio]),
textarea,
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}


/* I E   S T Y L E S */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .block {
        width: 100%;
    }
}

#hamburger-icon {
    position: relative;
    display: none;
    width: 18px;
    height: 14px;
}

#hamburger-icon .line {
    position: absolute;
    left: 0;
    display: block;
    background: #EC441E;
    width: 18px;
    height: 2px;
    transition: .4s;
    -webkit-transition: .4s;
    -moz-transition: .4s;
}

.mobile-menu {
    display: none;
}

.line.line-1 {
    top: 0;
}

.line.line-3 {
    top: 100%;
}

.line.line-2 {
    top: 50%;
}

#hamburger-icon.active .line-1 {
    transform: translateY(7px) translateX(0) rotate(45deg);
    -webkit-transform: translateY(7px) translateX(0) rotate(45deg);
    -moz-transform: translateY(7px) translateX(0) rotate(45deg);
    -ms-transform: translateY(7px) translateX(0) rotate(45deg);
    -o-transform: translateY(7px) translateX(0) rotate(45deg);
}

#hamburger-icon.active .line-2 {
    opacity: 0;
}

#hamburger-icon.active .line-3 {
    transform: translateY(-7px) translateX(0) rotate(-45deg);
    -webkit-transform: translateY(-7px) translateX(0) rotate(-45deg);
    -moz-transform: translateY(-7px) translateX(0) rotate(-45deg);
    -ms-transform: translateY(-7px) translateX(0) rotate(-45deg);
    -o-transform: translateY(-7px) translateX(0) rotate(-45deg);
}

.ov-hidden {
    overflow: hidden;
}

.top-wrap {
    padding: 0 0 184px 0;
    .container {
        max-width: 1170px;
    }
    .title {
        font-weight: 600;
        font-size: 48px;
        line-height: 112%;
        letter-spacing: -0.02em;
        color: #121212;
        margin-bottom: 51px;
        span {
            color: #EC441E;
        }
    }
}

form {
    button.btn-orange {
        display: block;
        max-width: 291px;
        width: 100%;
        margin: 0 auto;
        margin-top: 24px;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #FFFFFF;
        background: linear-gradient(92.61deg, #EC441E 0%, #E41414 100%);
        padding: 14px 0 14px 0;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
    }
}

.top-form {
    max-width: 463px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.12);
    padding: 20px 42px 23px 42px;
    border-radius: 5px;
    .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #707070;
        margin-bottom: 19px;
    }
    input {
        border: none;
        margin-bottom: 16px;
    }
}

.nice-select {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 0 0 0;
    height: 59px;
    padding-left: 25px;
    padding-right: 30px;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    background-color: #fff;
    cursor: pointer;
    background: #F4F4F4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    transition: .2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    margin-bottom: 16px;
    .current {
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #8C8C8C;
    }
}

.nice-select:after {
    content: '';
    display: block;
    width: 20px;
    height: 11px;
    position: absolute;
    right: 30px;
    top: 41%;
    display: block;
    background: url(../img/arr-select.svg) center no-repeat;
    background-size: cover;
    border: none;
    @include tr2;
}

.nice-select .list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 9;
    margin-top: 0;
    padding: 0;
    opacity: 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    overflow: hidden;
    pointer-events: none;
    transform-origin: 50% 0;
    transform: scale(.75) translateY(-21px);
    transition: .2s cubic-bezier(.5, 0, 0, 1.25), opacity .15s ease-out;
    border: 2px solid #E41514;
}

.nice-select .option {
    cursor: pointer;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #121212;
    list-style: none;
    outline: 0;
    padding-left: 24px;
    padding-right: 24px;
    text-align: left;
    transition: .2s;
    margin-bottom: 16px;
    &:hover {
        color: #EC441E;
    }
    &:first-child {
        margin-top: 14px;
    }
}

.nice-select .option.disabled {
    opacity: 0.3;
}

.nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1) translateY(0);
}

.nice-select.open:after {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}

.exs {
    margin-bottom: 133px;
    .container {
        max-width: 1170px;
    }
}

.exs-items {
    display: flex;
    justify-content: center;
    align-items: center;
}

.exs-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 175px;
    &:last-child {
        margin-right: 0;
    }
    img {
        margin-right: 24px;
    }
    span {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #121212;
    }
}

.breaking {
    padding-bottom: 64px;
    .title {
        margin-bottom: 64px;
    }
}

.breaking-item {
    display: block;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    padding: 24px 20px 24px 32px;
    margin-bottom: 20px;
    z-index: 5;
    ul {
        margin-bottom: 36px;
    }
    img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: -1;
    }
}

.breaking-item-no-img {
    padding: 36px 20px 37px 32px;
}

.breaking-item__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 112%;
    letter-spacing: -0.02em;
    color: #121212;
    margin-bottom: 26px;
}

.breaking-item__price {
    font-weight: 600;
    font-size: 24px;
    line-height: 112%;
    letter-spacing: -0.02em;
    color: #E41414;
}

.form-breaking {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 44px 0 0 0;
    input {
        display: block;
        max-width: 379px;
        width: 100%;
        margin-right: 24px;
        margin-bottom: 0;
    }
    button.btn-orange {
        margin: 0;
        max-width: 283px;
    }
}

.sale {
    padding: 100px 0 100px 0;
    background: linear-gradient(92.61deg, #EC441E 0%, #E41414 100%);
    .desc {
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #121212;
        margin-bottom: 64px;
    }
    .title {
        font-weight: 600;
        font-size: 40px;
        line-height: 112%;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #121212;
        margin-bottom: 12px;
    }
    .date-sale {
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        color: #121212;
        margin-bottom: 64px;
        span {
            margin-left: 8px;
            color: #EC441E;
        }
    }
    b {
        display: block;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #121212;
        margin-bottom: 10px;
    }
    form {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 0 0;
        input {
            display: block;
            max-width: 379px;
            width: 100%;
            margin-right: 24px;
            margin-bottom: 0;
        }
        button.btn-orange {
            margin: 0;
            max-width: 284px;
        }
    }
}

.sale-box {
    position: relative;
    padding: 30px 24px 31px 429px;
    background: #FFFFFF;
    border-radius: 5px;
    z-index: 5;
    img {
        position: absolute;
        top: -84px;
        left: -204px;
        width: 620px;
        min-height: 620px;
        z-index: -1;
    }
}

.about {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 59px;
}

.about__left {
    padding: 34px 0 0 383px;
    width: 44%;
    p {
        margin-bottom: 35px;
    }
}

.about__right {
    position: relative;
    width: 49.306%;
    height: 0;
    padding-bottom: 41.459%;
    img {
        @include imgCenter;
    }
}

.masters {
    margin-bottom: 136px;
    .title {
        margin-bottom: 53px;
    }
}

.master-item__img {
    margin-bottom: 24px;
}

.master-item__name {
    font-weight: 600;
    font-size: 24px;
    line-height: 112%;
    letter-spacing: -0.02em;
    color: #121212;
    margin-bottom: 12px;
}

.master-item__desc {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #121212;
}

.rewiews {
    margin-bottom: 64px;
    .title {
        margin-bottom: 42px;
    }
    .row .col-lg-6 {
        display: none;
        &:nth-child(-n+4) {
            display: block;
        }
    }
}

.rewiews.active {
    .col-lg-6 {
        display: block;
    }
}

.rewiews-item {
    padding: 24px 32px 64px 42px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    margin-bottom: 20px;
}

.rewiews-item__top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 35px;
}

.rewiews-item-img {
    position: relative;
    margin-right: 32px;
    width: 99px;
    height: 99px;
    overflow: hidden;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    img {
        @include imgCenter;
    }
}

.rewiews-item-name {
    font-weight: 600;
    font-size: 24px;
    line-height: 112%;
    letter-spacing: -0.02em;
    color: #121212;
}

.rewiews-item-date {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #6A6A6A;
    margin-left: auto;
}

.rewiews-text {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #121212;
}

.btn-more {
    display: block;
    max-width: 288px;
    width: 100%;
    margin: 0 auto;
    margin-top: 22px;
    padding: 14px 0 14px 0;
}

.note {
    position: relative;
    z-index: 5;
    padding: 178px 0 178px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgba(104, 104, 104, 0.75);
        z-index: -1;
    }
    .title {
        font-weight: 600;
        font-size: 40px;
        line-height: 112%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 42px;
    }
    .desc {
        max-width: 718px;
        margin: 0 auto;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 42px;
        b {
            font-weight: 600;
        }
    }
    .btn-orange {
        display: block;
        max-width: 244px;
        width: 100%;
        margin: 0 auto;
        padding: 14px 0 14px 0;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #FFFFFF;
        background: linear-gradient(92.61deg, #EC441E 0%, #E41414 100%);
    }
}

.contacts {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 0 0 0;
    margin-bottom: 121px;
    .address {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #121212;
        margin-bottom: 24px;
        img {
            margin-right: 12px;
        }
    }
    .tel {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #121212;
        img {
            margin-right: 8px;
        }
    }
}

.contacts__text {
    width: 48%;
    padding: 156px 0 0 384px;
    .title {
        font-weight: 600;
        font-size: 40px;
        line-height: 112%;
        letter-spacing: -0.02em;
        color: #121212;
        margin-bottom: 46px;
    }
}

.map {
    position: relative;
    width: 49.306%;
    height: 600px;
    background: #ccc;
}

.contacts-text {
    margin-top: 42px;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #121212;
}

.footer {
    padding-bottom: 50px;
    .container {
        max-width: 1170px;
    }
    .btn-orange {
        display: none;
    }
}

.footer-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .logo {
        margin-right: 192px;
        position: relative;
        padding: 0 0 0 38px;
        span {
            white-space: nowrap;
            font-family: "Aldrich";
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: #121212;
        }
        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: -10px;
            width: 30px;
            height: 40px;
            background: url(../img/logo.svg) no-repeat left center;
        }
    }
    .phone {
        margin-left: auto;
    }
}

.form-popup {
    width: 100%;
    input {
        display: block;
        max-width: 364px;
        width: 100%;
        margin: 0 auto;
        padding: 0 15px 0 0;
        text-align: left;
        background: transparent;
        box-shadow: none;
        border-radius: 0;
        border-bottom: 3px solid #E83019;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #919191;
        height: 41px;
        margin-bottom: 16px;
    }
    .btn-orange {
        display: block;
        margin: 0 auto;
        max-width: 294px;
        width: 100%;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #FFFFFF;
        margin-bottom: 57px;
    }
}

.popup-title {
    font-weight: bold;
    font-size: 36px;
    line-height: 102%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #000000;
    margin-bottom: 16px;
}

.popup-desc {
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: #595959;
    margin-bottom: 60px;
}

.agree {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #8F8F8F;
}

@media screen and (min-width:992px) {
    .btn-orange:hover {
        opacity: 0.5;
    }
    .modal-close:hover {
        opacity: 0.7;
    }
}


/* 
    M O B I L E
*/

@media screen and (max-width: 1650px) {
    .contacts__text {
        padding-left: 150px;
    }
    .about__left {
        padding-left: 150px;
    }
}

@media screen and (max-width: 1300px) {
    .about {
        align-items: flex-start;
    }
}

@media screen and (max-width: 1200px) {
    html,
    body {
        min-width: 320px;
        width: 100%;
        overflow-x: hidden;
    }
    a:focus {
        outline: none;
    }
}

@media screen and (max-width: 1199px) {
    .header .container,
    .footer .container,
    .exs .container,
    .top-wrap .container {
        max-width: 960px;
    }
    .breaking-item__title {
        font-size: 20px;
    }
    ul:not(.menu, .list) li {
        font-size: 14px;
    }
    .breaking-item img {
        width: 36%;
    }
    .breaking-item {
        padding: 20px 15px 20px 20px;
    }
    .menu li {
        margin-right: 30px;
    }
    .phone {
        margin-left: 50px;
    }
    .header-box .logo {
        margin-right: 30px;
    }
    .top-wrap .title {
        font-size: 38px;
    }
    .top-wrap {
        padding: 0 0 115px 0;
    }
    .top-form .title {
        font-size: 20px;
    }
    .header.fixed+.top-wrap {
        padding: 20px 0 95px 0;
    }
    .header.fixed~.top-img {
        height: 650px;
    }
    .top-img {
        height: 800px;
    }
    .header-box .logo {
        padding: 0 0 3px 38px;
    }
    .title {
        font-size: 32px;
    }
    .rewiews-item-name {
        font-size: 20px;
    }
    .rewiews-item {
        padding: 20px 20px 55px 30px;
    }
    .rewiews-item-img {
        width: 85px;
        height: 85px;
        margin-right: 15px;
    }
    .rewiews-item-date {
        font-size: 14px;
    }
    .rewiews-item__top {
        margin-bottom: 20px;
    }
    .about__left {
        padding-left: 80px;
    }
    .contacts__text {
        padding-left: 80px;
    }
    .sale .title {
        font-size: 32px;
    }
    .modal-open {
        overflow: hidden;
        padding-right: 0px !important;
    }
    .sale form button.btn-orange {
        font-size: 20px;
    }
    .exs-item {
        margin-right: 110px;
    }
    .exs-item span {
        font-size: 14px;
    }
    .about__left p {
        font-size: 14px;
    }
}

@media screen and (max-width: 991px) {
    .btn-orange:active {
        opacity: 0.5;
    }
    .header .container,
    .footer .container,
    .exs .container,
    .top-wrap .container {
        max-width: 720px;
    }
    .header-box {
        justify-content: space-between;
    }
    .top-wrap .title {
        font-size: 32px;
        text-align: center;
    }
    .top-form {
        margin: 0 auto;
    }
    .top-form .title {
        font-size: 20px;
    }
    body {
        padding-top: 63px;
    }
    #hamburger-icon {
        display: block;
    }
    .top-wrap {
        padding: 25px 0 115px 0;
    }
    .top-img {
        height: 680px;
    }
    .header {
        margin-bottom: 0;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        z-index: 9999;
        background: #fff;
        padding: 20px 0;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
    }
    .mobile-menu {
        position: fixed;
        top: 64px;
        right: 0;
        width: 100vw;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        height: calc(100vh - 64px);
        z-index: 30;
        overflow: auto;
        background: rgba(0, 0, 0, 0.79);
        padding: 35px 15px 25px;
        box-sizing: border-box;
        transform: translate(100vw, 0);
        -webkit-transform: translate(100vw, 0);
        -moz-transform: translate(100vw, 0);
        -ms-transform: translate(100vw, 0);
        -o-transform: translate(100vw, 0);
        transition: .3s ease-in-out;
        -webkit-transition: .3s ease-in-out;
        -moz-transition: .3s ease-in-out;
        -ms-transition: all .3s ease-in-out;
        -o-transition: .3s ease-in-out;
    }
    .mobile-menu.active {
        transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
    }
    .mobile-menu .menu {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    .mobile-menu .menu li {
        display: block;
        margin: 0 0 20px 0;
    }
    .mobile-menu .menu a {
        font-size: 24px;
        color: #fff;
        font-weight: 600;
    }
    .header .phone {
        color: #fff;
        margin: 0 auto;
        margin-bottom: 25px;
    }
    .header .btn-orange {
        margin: 0 auto;
        padding: 14px 0 14px 0;
        max-width: 288px;
        width: 100%;
    }
    .header-box>.menu,
    .header-box>.phone {
        display: none;
    }
    .header-box .btn-orange {
        margin-right: 30px;
    }
    .exs-items {
        flex-direction: column;
        justify-content: center;
    }
    .exs-item {
        margin-right: initial;
        width: 42%;
        margin-bottom: 35px;
    }
    .exs {
        margin-bottom: 50px;
    }
    .breaking-item img {
        display: none;
    }
    .sale-box img {
        display: none;
    }
    .sale-box {
        padding: 30px 24px 31px 24px;
    }
    .about__left,
    .contacts__text {
        padding-left: 30px;
    }
    .master-item__name {
        font-size: 20px;
    }
    .master-item__desc {
        font-size: 14px;
    }
    .master-item__img {
        margin-bottom: 12px;
    }
    .breaking .title {
        margin-bottom: 25px;
    }
    .rewiews-item {
        padding: 20px 20px 35px 30px;
    }
    .note .title {
        font-size: 32px;
        br {
            display: none;
        }
    }
    .note .desc {
        font-size: 18px;
    }
    .note {
        padding: 100px 0 100px 0;
    }
    .bottom-menu {
        display: none;
    }
    .contacts__text {
        padding: 30px 0 0 30px;
    }
    .map {
        height: 400px;
    }
    .contacts__text .title {
        font-size: 32px;
    }
    .menu a:active {
        color: #EC441E;
    }
}

@media screen and (max-width: 767px) {
    .container {
        width: 320px;
    }
    .modal-content {
        margin: 0 15px;
    }
    .header-box .logo {
        margin-right: 0;
    }
    .header-box>.btn-orange {
        display: none;
    }
    body {
        padding-top: 63px;
    }
    .top-wrap .title {
        font-size: 22px;
        margin-bottom: 30px;
        br {
            display: none;
        }
    }
    .top-wrap .title {
        font-size: 18px;
        line-height: 1.3;
    }
    .nice-select .option {
        font-size: 14px;
        padding-left: 12px;
        padding-right: 12px;
    }
    .top-form {
        padding: 20px 15px 23px 15px;
    }
    .nice-select {
        padding-left: 12px;
    }
    input {
        font-size: 14px;
        padding: 0 12px;
    }
    .nice-select .current {
        font-size: 14px;
    }
    .nice-select:after {
        right: 10px;
    }
    .top-img {
        height: 600px;
    }
    .exs-item {
        width: 100%;
    }
    .about__left,
    .contacts__text {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
    .about__right {
        display: none;
    }
    .title {
        font-size: 28px;
    }
    .form-breaking {
        flex-direction: column;
    }
    .form-breaking input {
        margin-right: 0;
        margin-bottom: 15px;
    }
    .form-breaking button.btn-orange {
        max-width: 100%;
    }
    .sale {
        padding: 50px 0 50px 0;
    }
    .sale .title {
        font-size: 24px;
    }
    .sale .date-sale {
        font-size: 18px;
        margin-bottom: 30px;
    }
    .sale .date-sale span {
        margin-left: 0;
    }
    .sale .desc {
        margin-bottom: 30px;
    }
    .sale form {
        flex-direction: column;
    }
    .sale form input {
        margin-right: 0;
        margin-bottom: 15px;
    }
    .master-item {
        margin-bottom: 35px;
    }
    .masters .title {
        margin-bottom: 20px;
    }
    .about {
        margin-bottom: 45px;
    }
    .masters {
        margin-bottom: 75px;
    }
    .rewiews-item-img {
        flex-shrink: 0;
        margin-bottom: 10px;
    }
    .rewiews-item__top {
        flex-direction: column;
        align-items: flex-start;
    }
    .rewiews-item-name {
        margin-bottom: 10px;
    }
    .rewiews-item-date {
        margin-left: 0;
    }
    .rewiews-text {
        font-size: 14px;
    }
    .rewiews-item {
        padding: 20px 12px 25px 20px;
    }
    .note .title {
        font-size: 26px;
    }
    .note {
        padding: 50px 0 50px 0;
    }
    .contacts {
        flex-direction: column;
    }
    .map {
        width: 100%;
    }
    .contacts__text {
        margin-bottom: 35px;
    }
    .footer-box {
        flex-direction: column;
    }
    .footer-box .logo {
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .contacts {
        margin-bottom: 50px;
    }
    .footer-box .phone {
        margin-left: 0;
    }
    .about,
    .contacts {
        justify-content: center;
        align-items: center;
    }
    .about__left,
    .contacts__text {
        max-width: 320px;
    }
    .modal-content {
        padding: 50px 20px 35px 20px;
    }
    .popup-title {
        font-size: 26px;
    }
    .popup-desc {
        font-size: 18px;
        text-align: center;
        margin-bottom: 30px;
    }
    .modal-close {
        top: 15px;
        right: 15px;
    }
    .agree {
        font-size: 12px;
        br {
            display: none;
        }
    }
    .form-popup .btn-orange {
        margin-bottom: 25px;
    }
    .footer .btn-orange {
        display: block;
        margin-top: 20px;
        margin-left: 0;
        width: 100%;
        padding: 14px 0 14px 0;
    }
}

@media screen and (max-width: 575px) {}